<template>
  <div>
    <base-modal v-model="showModal"
                :id="`msp-forsage-${program}`"
                :title="`Для того, чтобы мы могли проверить соответствие Вашей компании базовым критериям программы «${programName}», просим Вас ввести следующие сведения`"
                modal-class="modal-lg"
                :icon="false">
      <div class="mt-3">
        <form v-if="showModal && !needAccepted" @submit.prevent="validate()">
          <base-input label="Введите ОГРН/ОГРНИП компании"
                      :disabled="organization.id"
                      :validate="'length:13,15'"
                      :mask="{mask: '000000000000000', lazy: true}"
                      name="ogrn" v-model="model.ogrn" />
          <base-input-wrapper label="Выберите регион осуществления деятельности компании" name="region_name_ru">
            <autocomplete key="region_name_ru" :validate="'required'" :class="{invalid: !model.region}" path="regions?filters[country]=1&filters[regionNameRu]=" id="region" name="region_name_ru" v-model="model.region" placeholder="Регион" />
          </base-input-wrapper>
          <form-error v-if="error" :error="error" />
          <div class="d-flex justify-content-between align-items-center mt-3">
            <button type="button" class="btn btn-md btn-secondary mr-5" data-dismiss="modal">Отмена</button>
            <base-button-send @click="validate()" text="Отправить" />
          </div>
        </form>
        <msp-forsage-accepted v-if="needAccepted" :program="program" />
      </div>
    </base-modal>
    <button class="link-target" @click="show()"><span>Подать заявку</span></button>
  </div>
</template>

<script>
import BaseModal from "@/components/modal/BaseModal.vue";
import modalMixin from "@/mixins/modalMixin";
import BaseInput from "@/components/form/BaseInput.vue";
import Autocomplete from "@/components/Autocomplete.vue";
import BaseInputWrapper from "@/components/form/BaseInputWrapper.vue";
import validateMixin from "@/mixins/validateMixin";
import BaseButtonSend from "@/components/form/BaseButtonSend.vue";
import ApiService from "../../../services/api.service";
import FormError from "@/components/FormError.vue";
import {mapGetters, mapMutations} from "vuex";
import MspForsageAccepted from "@/components/accelerator/MspForsageAccepted.vue";
import {TokenService} from "../../../services/token.service";

export default {
  name: "MspForsageOrder",
  components: {MspForsageAccepted, FormError, BaseButtonSend, BaseInputWrapper, Autocomplete, BaseInput, BaseModal},
  mixins: [modalMixin, validateMixin],
  beforeCreate() {
    const isLength = (value, { first, second } = {}) => {
      value = value.replaceAll(' ', '')
      if(value.length > 15) {
        value = value.slice(0, -1)
      }
      return Number(first) === value.length || Number(second) === value.length;
    };
    const paramNames = ['first', 'second'];
    this.$validator.extend('length', isLength, {
      paramNames
    });
  },
  computed: {
    programName() {
      return this.program === 1 ? 'Экспортный форсаж' : 'Экспортный Мини-МВА'
    },
    ...mapGetters(['organization']),
    region() {
      return this.$authCheck() ? TokenService.getUserObj().region : {}
    }
  },
  data() {
    return {
      model: {},
      error: null,
      needAccepted: false
    }
  },
  mounted() {

    this.model.region = this.region

    this.$on('on-submit', async ()=> {
      this.error = null
      let res = await ApiService.post('msp-forsage/order', {
        ...this.model,
        region: this.model.region.id,
        program: this.program
      })
      if(res.status === 400) {
        this.error = {
          ...res.data,
          title: 'К сожалению, в настоящее время Ваша компания не может принять участие в программе по следующим причинам:'
        }
      } else if(res.status === 200) {
        this.showModal = false
        this.setMspForsageOrderRequest(res.data.id)
        if(this.$authCheck()) {
          window.location.href = this.program === 1 ? '/lk/forsage/company' : '/lk/msp/company'
        } else {
          await this.$router.push('/sign-up?type=company&ogrn=' + this.model.ogrn)
        }

        this.model = {}
      } else if(res.status === 202) {
        this.needAccepted = true
        this.setMspForsageOrderRequest(res.data.id)
      } else {
        this.error = res.data
      }
    })
  },
  methods: {
    ...mapMutations(['setMspForsageOrderRequest'])
  },
  props: {
    program: {
      type: Number,
      required: true
    }
  },
  watch: {
    'organization.ogrn'(val) {
      if(val) {
        this.model.ogrn = val.replaceAll(' ', '')
      }
    }
  }
}
</script>

<style scoped>

</style>